@import '../../../styles/main.scss';

.videoDetailsPage {
  padding: $universalSize-24 0;
}

.videoPlayerContainer {
  display: flex;
  flex-direction: column;
  gap: $universalSize-40;
  @include respond(medium) {
    gap: $universalSize-24;
  }
}

.itemBottom {
  border: $universalSize-1 solid $white-color-border;
  width: 85%;
  margin: 0 auto;
  overflow: hidden;

  @include respond(medium) {
    width: 100%;
    margin: 0;
  }
}

.videoSection {
  display: flex;
  flex-direction: column;
  gap: $universalSize-14;
  justify-content: center;
  align-items: center;
}

.videoDetailsContainer {
  display: flex;
  flex-direction: column;
  gap: $universalSize-40;
  width: 100%;

  @include respond(medium) {
    gap: $universalSize-24;
  }
}

.navBarContainer {
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    background: $black-color;
    height: $universalSize-50;
  }
  .imageWrapper {
    padding: $universalSize-20 $universalSize-14;
  }
}
