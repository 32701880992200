@import '../../../styles/main.scss';
.pageChangeHandler {
  display: flex;
  gap: $universalSize-16;
  align-items: center;
  width: 100%;
  padding: 0 $universalSize-56;
  max-width: $universalSize-1320;
  margin: auto;
  justify-content: end;

  .arrow {
    background-color: transparent;
    border: none;
    font-size: $universalSize-24;
    cursor: pointer;
    margin-bottom: $universalSize-4;
  }

  .arrow:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
