@import '../../../styles/main.scss';

.storiesSectionRootContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;

  &:not(:first-child) {
    padding-top: $universalSize-18;
    @include respond(medium) {
      padding-top: $universalSize-8;
    }
  }
  &.categoryMaxWidth {
    width: 100%;
  }

  &.storiesSectionRootContainerPosts {
    margin-top: $universalSize-60;
    max-width: $universalSize-1320;
  }

  @include respond(medium) {
    gap: $universalSize-32;
  }

  .categoryName {
    display: flex;
    font-size: $universalSize-16;
    flex-direction: column;
    margin-bottom: $universalSize-12;
    font-weight: $font-weight-card-heading;

    &.hidden {
      display: none;
    }

    &.categoryNamePosts {
      padding-left: $universalSize-20;
    }

    @include respond(medium) {
      margin-bottom: -($universalSize-20);
    }
  }

  .tabContent {
    display: flex;
    flex-direction: column;
    gap: $universalSize-16;

    &.tabContentPosts {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 0;
      margin-bottom: $universalSize-170;

      @include respond(medium) {
        grid-template-columns: 1fr;
      }
    }
  }

  .readMoreStoriesButton {
    display: flex;
    margin-top: $universalSize-56;
    height: $universalSize-60;
    border-radius: $universalSize-12;
    padding: $universalSize-16;
    gap: $universalSize-12;
    background-color: $white-color;
    justify-content: center;
    align-items: center;
    border: $universalSize-1 solid $border-light-color;
    font-size: $font-size-20;
    line-height: $line-height-32;
    font-weight: $font-weight-500;
    cursor: pointer;

    @include respond(medium) {
      margin-top: $universalSize-28;
    }
  }
}

.storyItem {
  display: flex;
  justify-content: center;
  padding-top: $universalSize-32;
  gap: $universalSize-40;
  border-top: $universalSize-1 solid $border-light-color;

  @include respond(medium) {
    gap: $universalSize-16;
  }

  &.storyItemPosts {
    padding-top: $universalSize-20;

    &:last-child {
      div {
        border-right: 0;
      }
    }
}

  .storyDetails {
    align-items: center;
    display: flex;
    gap: $universalSize-16;
    width: 100%;

    &.storyDetailsPosts {
      flex-direction: column;
      padding: 0 $universalSize-20;
      border-right: $universalSize-1 solid rgba(0, 0, 0, 0.08);


      @include respond(medium) {
        justify-content: flex-start;
      }
    }

    @include respond(medium) {
      justify-content: center;
      flex-direction: column;
    }

    .imageContainer {
      width: $universalSize-220;
      max-width: 30%;
      aspect-ratio: 16/9;

      @include respond(medium) {
        max-width: 100%;
        width: 100%;
      }

      &.imageContainerPosts {
        display: flex;
        align-items: center;
        aspect-ratio: 16/9;
        max-width: 100%;
        width: 100%;
      }

      .storyHeroImage {
        cursor: pointer;
        width: 100%;
        height: 100%;
        border-radius: $universalSize-4;
        overflow: hidden;
        object-fit: cover;

        @include respond(medium) {
          object-fit: cover;
          aspect-ratio: 16/9;
          width: 100%;
          height: 100%;
          border-radius: $universalSize-2;
        }
      }
    }

    .storyItemHeader {
      display: flex;
      cursor: pointer;
      flex-direction: column;
      gap: $universalSize-8;
      max-width: 70%;
      width: 100%;

      @include respond(medium) {
        max-width: 100%;
      }

      &.storyItemHeaderPosts {
        max-width: 100%;
      }

      .storyTitle {
        font-size: $font-size-20;
        line-height: $line-height-40;
        font-weight: $font-weight-card-heading;
        cursor: pointer;

        &.storyTitlePosts {
          line-height: $universalSize-28;
        }
        @include respond(medium) {
          font-size: $universalSize-20;
          line-height: $line-height-32;
        }
      }

      .storyDescription {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: ellipsis;
        white-space: normal;
        overflow-y: hidden;
        font-size: $universalSize-16;
        line-height: $line-height-small;
        color: $purple-variant-2;
      }

      .authorAndDate {
        margin-top: $universalSize-8;
        color: rgba(0, 0, 0, 0.55);
        font-size: $font-size-14;
      }
    }
  }
}
