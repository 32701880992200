@import '../../../styles/main.scss';

.brandLandingContainer {
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  max-width: $universalSize-1320;
  padding-top: $universalSize-36;
  margin: auto;
  @include respond(x-large) {
    grid-template-columns: repeat(9, 1fr);
  }
  @include respond(medium) {
    display: flex;
    flex-direction: column;
  }
  a {
    color: $blue-color-600;
    text-decoration: none;
  }

  .topPostsBig {
    grid-column: span 8;
    position: relative;
    padding: 0 $universalSize-20;
    img {
      aspect-ratio: 16 / 9;
    }
    @include respond(x-large) {
      grid-column: span 6;
    }
    @include respond(medium) {
      display: flex;
      flex-direction: column;
    }
  }

  .topPostsSmall {
    display: flex;
    flex-direction: column;
    grid-column: 9 / span 4;
    padding: 0 $universalSize-20;
    border-left: $universalSize-1 solid $border-color;
    border-right: $universalSize-1 solid $border-color;
    img {
      aspect-ratio: 16 / 9;
    }
    @include respond(x-large) {
      grid-column: 7 / -1;
    }
    @include respond(medium) {
      display: flex;
      flex-direction: column;
    }
  }
  .featured {
    grid-column: 13 / -1;
    @include respond(x-large) {
      grid-column: 1 / -1;
    }
    @include respond(medium) {
      display: flex;
      flex-direction: column;
    }

    .featuresOuter {
      padding: 0 $universalSize-20;
      .featuresInner {
        overflow: hidden;
        margin: 0 auto;
        width: 100%;

        .featureTitle {
          font-size: $font-size-12;
          font-weight: $font-weight-500;
          text-transform: uppercase;
          padding: $universalSize-12 0;
          border-bottom: $universalSize-1 solid $border-color;
        }

        .featuredFeed {
          img {
            width: $universalSize-90;
            height: $universalSize-54;
            margin-top: $universalSize-10;
          }
          @include respond(x-large) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: $universalSize-42;
          }
          @include respond(medium) {
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 0;
          }
        }
      }
    }
  }
}

.card {
  padding: $universalSize-12 0;
  border-bottom: $universalSize-1 solid $border-light-color;
  &.hideBorder {
    border-bottom: none;
  }
  .cardLink {
    display: flex;
    flex-direction: column;
    gap: $universalSize-20;
    &.directionNew {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    &:hover {
      opacity: 0.85;
    }

    .cardImage {
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        @include respond(medium) {
          aspect-ratio: 16/9;
          width: 90vw;
          max-width: 100%;
        }
      }
      &.imgFeatured {
        img {
          max-width: none;
          width: $universalSize-72;
          height: $universalSize-72;
        }
      }
      &.hideImage {
        display: none;
        @include respond(medium) {
          display: inline-block;
        }
      }
    }
    .cardWrapper {
      padding: $universalSize-10 0;
      .cardTitle {
        font-weight: $font-weight-section-heading;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow-y: hidden;
        &.titleTopPostBig {
          font-size: $universalSize-40;
          @include respond(medium) {
            font-size: $universalSize-32;
          }
        }
        &.titleTopPostSmall {
          font-size: $universalSize-20;
        }
        &.titleFeaturedPost {
          font-size: $universalSize-16;
        }
      }
      .cardExcerpt {
        display: -webkit-box;
        overflow-y: hidden;
        margin-top: $universalSize-12;
        font-size: $universalSize-18;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        &.hide1 {
          display: none;
          @include respond(medium) {
            display: -webkit-box;
            -webkit-line-clamp: 3;
          }
        }
        &.hide2 {
          display: none;
        }
      }
      .cardMeta {
        margin-top: $universalSize-6;
        display: flex;
        gap: $universalSize-4;
        flex-wrap: wrap;
        align-items: center;
        padding-bottom: $universalSize-4;
        font-size: $font-size-12;
        font-weight: $font-weight-500;
        color: rgba(0, 0, 0, 0.55);

        .cardAuthor {
          + .cardDate {
            &::before {
              content: '—';
              margin-right: $universalSize-4;
            }
          }
        }
      }
    }
  }
}
