@import '../../../styles/main.scss';
.allArticlesRoot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: $universalSize-1320;
  margin: 0 auto;
  padding: $universalSize-48 $universalSize-20;
  gap: $universalSize-24;
  &.allArticlesRootPosts {
    padding: 0;
  }

  @include respond(medium) {
    gap: $universalSize-24;
    padding: $universalSize-24 $universalSize-20;
    gap: $universalSize-12;
  }

  .seeMore {
    max-width: $universalSize-1320;
    width: 100%;
    font-size: $universalSize-16;
    font-weight: $font-weight-section-heading;
    margin: 0 auto;
    display: flex;
    gap: $universalSize-4;

    .linkSvg {
      color: $black-color;
    }
    @include respond(medium) {
      margin-bottom: $universalSize-24;
    }
  }
}
