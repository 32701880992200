@import '../../../styles/main.scss';
.videoPlayerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $universalSize-32;
  padding: $universalSize-48 0 $universalSize-38 0;
  position: relative;
  z-index: $zindex-4;
}

.contentContainer {
  display: flex;
  align-items: flex-start;
  gap: $universalSize-24;
  flex-wrap: wrap;
}

.heading {
  color: $white-color;
  font-size: $universalSize-26;
  line-height: $universalSize-36;
  font-weight: $font-weight-500;
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: $universalSize-16;
}

.videBackgroundContainer {
  position: relative;
  background-color: $blue-color-800;
  .absDiv {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $zindex-grid-circle-pattern;
  }
}
.scrollableContainer {
  height: $universalSize-400;
  background-color: rgba(4, 2, 34, 0.4);
  border-radius: $universalSize-12;
  overflow: auto;
  padding: $universalSize-20;

  &:-webkit-scrollbar {
    width: $universalSize-4;
  }

  &:-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: $universalSize-14;
    opacity: 80%;
  }
}
