@import '../../../styles/main.scss';

.videoContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 85%;
  margin: 0 auto;

  @include respond(medium) {
    width: 100%;
    margin: 0;
    padding: 0 $universalSize-20;
    flex-direction: column;
    gap: $universalSize-12;
  }
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: $universalSize-18;

  @include respond(medium) {
    gap: $universalSize-8;
  }
}

.title {
  color: $black-color;
  word-break: break-word;
  font-size: $universalSize-22;
  font-weight: $font-weight-card-heading;
  line-height: $universalSize-32;
  margin: 0;

  @include respond(medium) {
    font-size: $universalSize-18;
    line-height: $universalSize-24;
  }
}

.details {
  display: flex;
  align-items: center;
  color: $purple-variant-2;
  font-size: $universalSize-16;
  font-weight: $font-weight-para-heading;
  line-height: $universalSize-22;

  @include respond(medium) {
    font-size: $universalSize-12;
    line-height: $universalSize-16;
  }
}

.dot {
  margin: 0 $universalSize-8;
}

.rightSide {
  display: flex;
  justify-content: flex-end;

  &.fullScreen {
    gap: $universalSize-2;
  }

  @include respond(medium) {
    width: 100%;
  }
}

.oval {
  height: $universalSize-36;
  border-radius: $universalSize-32;
  background-color: $heavy-light-purple;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $universalSize-12;
  gap: $universalSize-12;
  cursor: pointer;

  &.fullScreenOval {
    background-color: transparent;
  }
}

.ovalText {
  color: $purple-variant-2;
  font-size: $universalSize-16;
  font-weight: $font-weight-card-heading;
  line-height: $universalSize-20;

  &.fullscreenText {
    color: $white-color;
  }
}
