@import '../../../styles/main.scss';

.videoBackgroundContainer {
  position: relative;
  background-color: $blue-color-800;
  .absDiv {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: $zindex-grid-circle-pattern;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: $universalSize-16;
    padding: $universalSize-40 0;
    z-index: $zindex-4;
    position: relative;
    .heading {
      color: $white-color;
      font-size: $universalSize-22;
      font-weight: $font-weight-para-heading;
      line-height: $universalSize-32;
      text-align: center;
    }
    .videoSlider {
      display: flex;
      overflow-x: auto;
      gap: $universalSize-16;
      padding: 0 $universalSize-20;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
        -webkit-overflow-scrolling: touch;
      }
    }
    .underLineComponent {
      display: flex;
      position: relative;
      padding: 0;
      align-self: center;
    }
    .itemBottom {
      height: $universalSize-2;
      border: $universalSize-1 solid $blue-color-850;
      width: 5vw;
      &.isSelected {
        height: $universalSize-2;
        border: $universalSize-1 solid $white-color;
        width: 8vw;
      }
    }
  }
}
