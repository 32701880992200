@import '../../../styles/main.scss';

.categoriesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 $universalSize-56;
  max-width: $universalSize-1320;
  margin: auto;
  @include respond(medium) {
    padding: 0 $universalSize-28;
  }
  .categoryIntroContainer {
    width: 100%;
    display: flex;
    padding: $universalSize-56 0;
    gap: $universalSize-32;
    @include respond(medium) {
      flex-direction: column-reverse;
      padding: $universalSize-28 0;
      gap: $universalSize-24;
    }
    .categoryInfo {
      flex-basis: 100%;
      align-content: center;

      .categoryHeading {
        @include desktopHeading2();
        font-size: $universalSize-42;
        @include respond(medium) {
          @include desktopHeading3();
        }
      }
      .categoryDescription {
        @include desktopPara2();
        @include respond(medium) {
          @include desktopPara3();
        }
      }
    }
    .categoryImageContainer {
      flex-basis: 100%;
      text-align: center;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .articlesContainer {
    width: 100%;
    padding: $universalSize-56 0;
  }
}
