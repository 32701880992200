@import '../../../styles/main.scss';

.container {
  aspect-ratio: 16 / 9;
  height: $universalSize-336;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: $universalSize-12;
  }
  &.fullWidth {
    width: 100%;
    height: $universalSize-600;
    display: flex;
    justify-content: center;
    background: $black-color;
    @include respond(medium) {
      height: auto;
    }
    iframe {
      aspect-ratio: 16/9;
      border-radius: 0;
      width: auto;
    }
  }
}
