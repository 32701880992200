@import '../../../styles/main.scss';
.modalWrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $universalSize-400;
  max-width: 80%;
  background-color: $white-color;
  padding: $universalSize-20;
  border-radius: $universalSize-12;
  box-shadow: 0 $universalSize-2 $universalSize-4 rgba(0, 0, 0, 0.2);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zindex-grid-root-container;
  background-color: rgba(0, 0, 0, 0.5);
}

.shareModal {
  border-radius: $universalSize-12;

  .modalHeader {
    display: flex;
    margin-bottom: $universalSize-24;
    justify-content: space-between;
    width: $universalSize-360;

    .modalTitle {
      margin: 0;
      font-size: $universalSize-16;
      font-weight: $font-weight-card-heading;
      line-height: $universalSize-22;
    }

    .closeButton {
      cursor: pointer;
    }
  }

  .iconWrapper {
    display: flex;
    gap: $universalSize-26;
    margin-bottom: $universalSize-24;

    .icon {
      cursor: pointer;
    }
  }

  .separator {
    border-top: $universalSize-1 solid $white-color-border;
    margin: $universalSize-24 0;
  }

  .copyLinkText {
    margin: $universalSize-24 0;
    font-size: $universalSize-14;
    font-weight: $font-weight-500;
    line-height: $universalSize-20;
  }

  .inputWrapper {
    display: flex;
    align-items: center;
    height: $universalSize-48;
    width: $universalSize-360;
    border: $universalSize-1 solid $white-color-border;
    border-radius: $universalSize-12;
    padding: 0 $universalSize-12;
    max-width: 100%;

    .input {
      flex: 1;
      border: none;
      outline: none;
    }

    .copyIcon {
      cursor: pointer;
    }
  }
}
