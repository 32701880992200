@import '../../../styles/main.scss';

.container {
  display: flex;
  align-items: flex-start;
  gap: $universalSize-30;
  max-width: $universalSize-600;

  .leftSide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 75%;

    .title {
      word-break: break-word;
      margin: 0 0 $universalSize-8 0;
      font-size: $universalSize-16;
      font-weight: $font-weight-card-heading;
      line-height: $universalSize-22;
      color: $white-color;
    }

    .details {
      display: flex;
      align-items: center;
      font-size: $universalSize-14;
      font-weight: $font-weight-para-heading;
      line-height: $universalSize-20;
      color: $white-color;

      .dot {
        margin: 0 $universalSize-8;
      }
    }
  }

  .rightSide {
    display: flex;
    justify-content: flex-end;
    width: 25%;
    gap: $universalSize-15;

    .oval {
      height: $universalSize-28;
      border-radius: $universalSize-16;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $universalSize-6;
      gap: $universalSize-8;
      background-color: #ffffff26;
      width: $universalSize-66;
      cursor: pointer;
    }
  }

  .ovalText {
    font-size: $universalSize-12;
    font-weight: $font-weight-para-heading;
    line-height: $universalSize-12;
    color: $white-color;
  }
}
